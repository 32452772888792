<template lang='pug'>
	div
		b-card.user-project-select.brand-card-border.dropdown-custom(no-body @click="isShow = !isShow, $bvModal.show('project-selection'), $emit('click', $event)" :class="{'show-project-selection': isShow}")
			b-card-body
				.d-flex.justify-content-between.align-items-center
					.media.align-items-center.img-with-text(v-if='lastOpenedProjectId && openedProject && openedProject.id')
						.avatar-rect.avatar-rect-sm(v-if='!openedProject.isNeuro()')
							img.avatar-rect-img(v-if="openedProject.image" :src='openedProject.image.contentUrl' :alt='openedProject.title')
						.avatar-rect-neuro(v-if='openedProject.isNeuro()')
							img.avatar-rect-img(v-if="openedProject.image" :src='openedProject.image.contentUrl' :alt='openedProject.title')
							span.avatar-rect-span(v-if="!openedProject.image && openedProject && openedProject.title") {{ `${openedProject.title.replace(/[^a-zA-Zа-яА-Я0-9]/g, '').slice(0, 1)}` }}
							img.avatar-rect-img(v-else src='/assets/img/neurobots/neurobot.webp' alt='Neurobot')
						span.b4.user-project-select__title {{ openedProject.title ? openedProject.title : $t('instruction.step0.neurobot.newNeurobot') }}
					.media.align-items-center(v-else-if='list.length == 0')
						span.b4 {{ $t('project.emptyProjects') }}
					.media.align-items-center(v-else)
						span.b4 {{ $t('project.needSelectProject') }}
					.toggle(:class="{'rotate': isShow}")

		project-selection.user-project-select__selection(v-if="isShow" @isShow="d => isShow = d")

		modal-general(v-if="isLarge" :id="'project-selection'" :title="$t('h1.projectSelection')")
			project-selection(@isShow="$bvModal.hide('project-selection')")
</template>

<script>
import { mapGetters } from 'vuex';
import ProjectSelection from "@/components/Common/ProjectSelection";
import ModalGeneral from '@/components/Modals/ModalGeneral';

export default {
	name: 'UserProjectSelect',
	components: {
		ProjectSelection,
		ModalGeneral
	},
	data: () => ({
		isShow: false
	}),
	computed: {
		...mapGetters({
			lastOpenedProjectId: 'project/lastOpenedProjectId',
			list: 'project/itemsShort',
			openedProject: 'project/opened'
		}),
	},
	mounted() {
		document.addEventListener("click", e => {
			if (this.isShow == true && !e.target.classList.contains('user-project-select')) {
				this.isShow = false;
			}
		});
	},
	methods: {}
}
</script>

<style lang='scss'>
@import '~@/assets/css/sass/_mixins.scss';

.text-1-row {
	min-height: 22px;
	line-height: 22px;
}

.img-with-text {
	flex-shrink: 0;

	.text-1-row {
		max-width: 100px;
	}
}

.user-project-select {
	margin-bottom: 20px;
	cursor: pointer;

	.card-body {
		display: flex;
		align-items: center;
		min-height: 50px;
		padding: 0 14px;

		@include respond-below(md) {
			align-items: center;
		}
	}

	&__selection {
		z-index: 2;
		position: absolute;
		top: 130px;
		left: 30px;

		@include respond-below(md) {
			display: none;
		}
	}

	&__title {
		width: 99px;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;

		@include respond-below(md) {
			width: 93px;
		}
	}
}

.avatar-rect {
	margin-right: 9px;
}

.dropdown-custom {
	transition: var(--animation-time-short);

	.toggle {
		position: absolute;
		top: 16px;
		right: 13px;
		width: 18px;
		height: 18px;
		background: url('/assets/img/icons/arrow.svg');
		margin-left: 6px !important;
		border: none !important;
		transform: rotate(0);
		background-size: cover;
		transition: var(--animation-time-short);

		@include respond-below(md) {
			top: 15px;
			right: 20px;
			background-image: url('/assets/img/icons/arrow-right.svg');
		}

		&.rotate {
			transform: rotate(180deg);

			@include respond-below(md) {
				transform: rotate(0);
			}
		}
	}

	&:hover,
	&:active {
		border: 1px solid transparent !important;
		background-color: var(--foreground-color) !important;

		&.card {
			box-shadow: var(--box-shadow-hover) !important;
		}
	}

	&.show-project-selection {
		border: 1px solid var(--button-border-color) !important;
		background-color: var(--foreground-color) !important;
	}

	@include respond-below(md) {

		&:hover,
		&:active {
			background-color: transparent;
		}
	}
}
</style>
