<template lang="pug">
	b-modal#modalMobileMenu(centered ref="modalMobileMenu" scrollable hide-footer hide-header-close)
		template(#modal-header="{ close }")
			icon-close.mr-auto.modal-mobile-menu__icon-close(@click="close()")
			.logo.modal-mobile-menu__logo

		sidebar(@click="close")
</template>


<script>
import IconClose from "@/components/Common/IconClose";
import Sidebar from '@/containers/Sidebar';

export default {
	components : {
		Sidebar,
		IconClose
	},
	methods: {
		close() {
			this.$refs.modalMobileMenu.hide();
		},
	}
};
</script>

<style lang='scss'>
@import '~@/assets/css/sass/_mixins.scss';

#modalMobileMenu,
.modal-mobile-menu {
	height: 100%;

	&__icon-close {
		margin-left: 0 !important;
		margin-top: 8px;
	}

	&__logo {
		width: 71px;
		height: 17px;
		margin-top: 5.5px;
	}

	.modal-header {
		padding-bottom: 18px;
	}

	.modal-dialog {
		min-height: 100%;
	}

	.modal-body {
		padding: 0 24px;
		border-radius: var(--border-radius-rounded);

		/* Скрываем scrollbar для IE, Edge и Firefox */
		-ms-overflow-style: none;  /* IE и Edge */
		scrollbar-width: none;  /* Firefox */

		/* Скрываем scrollbar для Chrome, Safari и Opera */
		&::-webkit-scrollbar {
			display: none;
		}
	}

	.modal-content {
		border-radius: var(--border-radius-rounded);

        @include respond-below(md) {
			border-top-left-radius: 0 !important;
			border-bottom-left-radius: 0 !important;
		}
	}

	.sidebar {
		box-shadow: none;

		&::before {
			content: none;
		}

		.main-menu {
			width: 100%;
		}

		.dropdown-custom {
			max-width: 224px;
		}

		.wrapper-menu {
			&:first-child {
				display: none;
			}
		}
	}
}
</style>
