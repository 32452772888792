<template lang="pug">
	.project-selection
		my-input-search.mb-2.project-selection__search(v-model="query" :placeholder="$t('dashboards.search')")

		.m-auto(v-if="!empty && list.length == 0")
			.loader.primary.my-5.mx-auto

		.d-flex.flex-column.mb-2.project-selection__list(v-else)
			.d-flex.flex-column.m-auto.py-5(v-if="listSorted.length == 0")
				h1.mb-3.text-center 😢
				span.b4.text-grey.text-center {{ $t('errors.emptySearch') }}

			project-view(v-else v-for='p in listSorted' :key='p.id' :item='p' @onClick='goToProject')

		.btn.btn-border.btn-small.mt-auto.project-selection__button(@click='goToAddProject')
			span {{ $t('project.addTitle') }}
</template>

<script>
import ProjectView from '@/components/Common/ProjectView';
import {mapActions, mapGetters} from 'vuex';
import MyInputSearch from "@/components/UI/MyInputSearch";

export default {
	name: 'ProjectSelection',
	components: {
		ProjectView,
		MyInputSearch
	},
	data : () => ({
		query: null,
		empty: false
	}),
	created () {
		this.get({query: this.query, 'order[title]': 'asc', itemsPerPage: 100})
			.then( v => this.empty = v.length == 0)
	},
	computed: {
		...mapGetters({
			//list 				: 'project/items',
			list 				: 'project/itemsShort',
			opened 				: 'project/opened',
			lastOpenedProjectId : 'project/lastOpenedProjectId'
		}),
		listSorted () {
			return _.filter(_.sortBy(this.list, ({id}) => id == this.lastOpenedProjectId ? 0 : 1), l => !this.query ? l : l.title != null ? l.title.toUpperCase().includes(this.query.toUpperCase()) : '')
		}
	},
	methods: {
		...mapActions({
			get: 'project/shortList',
			//get: 'project/items'
		}),
		goToAddProject() {
			this.$emit('isShow', false);
			this.$router.push({name: 'add_project'})
		},
		goToProject(id) {
			this.$emit('isShow', false);
			this.$router.push({name: 'project_dashboard', params: {id: id}});
		}
	},
}
</script>

<style lang="scss">
@import '~@/assets/css/sass/_mixins.scss';

.project-selection {
	padding: 20px 0;
	width: 260px;
	border-radius: var(--border-radius-rounded);
	background-color: var(--foreground-color);
	border: 1px solid var(--border-line) !important;
	box-shadow: var(--box-shadow-hover) !important;

	@include respond-below(md) {
		width: 100%;
	}

	@include respond-below(sm) {
		display: flex;
		flex-direction: column;
		padding: 0;
		width: 100%;
		height: 100%;
		border: none !important;
		border-radius: 0 !important;
		box-shadow: none !important;
	}

	&__search {
		padding: 0 15px;

		@include respond-below(sm) {
			padding: 0;
		}
	}

	&__list {
		max-height: 50vh;
		overflow-y: auto;

		@include respond-below(sm) {
			max-height: 100%;
		}
	}

	&__button {
		margin: 0 15px;

		@include respond-below(sm) {
			margin: 0;
		}
	}
}
</style>
